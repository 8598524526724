#wrapperParallax {
  position: relative;
  z-index: 1060;
  min-height: 100vh;
}

#footerParallax {
  position: fixed;
  z-index: -1;
  left: 0;
  right: 0;
  bottom: 0;
}

@media only screen and (max-width: 991px) {
  #wrapperParallax {
    margin-bottom: 0 !important;
  }

  #footerParallax {
    position: static;
    text-align: center;
  }
}
.footer-top {
  margin-top: 200px;
}
.footer {
  background-color: #161616;
  padding-top: 30px;
  padding-bottom: 0;
}

.footer .footer-logo {
  max-width: 100%;
  width: 145px;
}

@media screen and (max-width: 991.98px) {
  .footer .footer-logo {
    margin: 0 auto 20px;
  }
}

.footer .footer-top {
  padding-top: 50px;
  padding-bottom: 47px;
  border-bottom: 2px solid #292929;
}

.footer .footer-center {
  padding-top: 0;
  padding-bottom: 35px;
}

@media screen and (min-width: 992px) {
  .footer .footer-center {
    padding-top: 100px;
  }
}

.footer .footer-widget {
  text-align: left;
  margin-right: 20px; /* Boşluk bırakmak için */
}

@media screen and (max-width: 568px) {
  .footer .footer-widget {
    text-align: center;
    margin-right: 0; /* Küçük ekranlarda boşluğu sıfırla */
    margin-bottom: 20px; /* Boşluk bırakmak için alt alta geçişte */
  }
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .footer .footer-widget {
    padding-bottom: 0;
    margin-bottom: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer .footer-widget {
    padding-bottom: 5px;
  }
}

.footer .footer-widget .footer-widget-title h5 {
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
  text-transform: capitalize;
  font-family: 'Rubik', sans-serif;
  margin-bottom: 22px;
}

@media only screen and (max-width: 991px) {
  .footer .footer-widget .footer-widget-title h5 {
    margin-bottom: 15px;
  }
}

.footer .widget-about p {
  margin-bottom: 17px;
}

.footer .widget-about .module-social {
  border-bottom: 0;
}

.footer .widget-newsletter .widget-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media screen and (min-width: 992px) {
  .footer .widget-newsletter .widget-content {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
}

.footer .widget-newsletter .widget-content p {
  font-family: 'Rubik', sans-serif;
  font-weight: 500;
  font-size: 18px;
  color: #ffffff;
  text-align: center;
  margin-bottom: 30px;
}

@media screen and (min-width: 992px) {
  .footer .widget-newsletter .widget-content p {
    text-align: right;
    margin-right: 30px;
    margin-bottom: 0;
  }
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
  .footer .widget-newsletter .widget-content p {
    font-size: 14px;
  }
}

.footer .widget-newsletter .widget-content form {
  overflow: hidden;
  margin-bottom: 0;
}

.footer .widget-newsletter .widget-content .form-newsletter {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  overflow: visible;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

@media screen and (max-width: 568px) {
  .footer .widget-newsletter .widget-content .form-newsletter {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.footer .widget-newsletter .widget-content .form-newsletter .form-control {
  background-color: transparent;
  border: 1px solid #292929;
  color: #b9b9b9;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 400;
  height: 50px;
  line-height: 50px;
  margin-bottom: 20px;
  border-radius: 3px;
  padding-left: 20px;
  padding-right: 20px;
  width: 270px;
  text-transform: capitalize;
}

@media screen and (min-width: 992px) {
  .footer .widget-newsletter .widget-content .form-newsletter .form-control {
    margin-bottom: 0;
  }
}

@media screen and (min-width: 569px) {
  .footer .widget-newsletter .widget-content .form-newsletter .form-control {
    margin-right: 30px;
    margin-bottom: 0;
  }
}

.footer
  .widget-newsletter
  .widget-content
  .form-newsletter
  .form-control::-webkit-input-placeholder {
  color: #b9b9b9;
}

.footer
  .widget-newsletter
  .widget-content
  .form-newsletter
  .form-control::-moz-placeholder {
  color: #b9b9b9;
}

.footer
  .widget-newsletter
  .widget-content
  .form-newsletter
  .form-control:-ms-input-placeholder {
  color: #b9b9b9;
}

.footer .widget-newsletter .widget-content .form-newsletter .btn {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 700;
  color: #ffffff;
  -webkit-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  border-radius: 3px;
}

@media screen and (max-width: 568px) {
  .footer .widget-newsletter .widget-content .form-newsletter .btn {
    width: 100%;
  }
}

.footer .widget-newsletter .widget-content .form-newsletter .btn:hover,
.footer .widget-newsletter .widget-content .form-newsletter .btn:active,
.footer .widget-newsletter .widget-content .form-newsletter .btn:focus {
  background-color: #c81a06;
  border-color: #c81a06;
}
.widget-about {
  margin-left: 180px;
}
.widget-links {
  margin-right: 220px; /* Sadece sağa kaydır */
}
.widget-contact {
  margin-right: 80px; /* Sağ taraftan boşluk */
  margin-left: -90px; /* Sola kaydırma */
}
@media screen and (max-width: 1530px) {
  .widget-links {
    margin-left: -10px;
  }
  .widget-about {
    margin-left: 80px;
  }
  .widget-contact {
    margin-left: -120px;
  }
}
@media screen and (max-width: 1400px) {
  .widget-links {
    margin-left: 10px;
  }
  .widget-about {
    margin-right: -70px;
  }
  .widget-contact {
    margin-left: -120px;
  }
}
@media screen and (max-width: 1220px) {
  .widget-links {
    margin-left: -10px;
  }
  .widget-about {
    margin-left: 0;
  }
}
@media screen and (max-width: 1000px) {
  .widget-links {
    margin-left: 10px;
  }
  .widget-about {
    margin-left: 10px;
  }
  .widget-contact {
    margin-left: 10px;
  }
}
.footer .widget-links ul {
  padding-left: 0;
  list-style: none;
  margin-bottom: 0;
  overflow: hidden;
  -webkit-transform: translateY(-5px);
  -ms-transform: translateY(-5px);
  transform: translateY(-5px);
}

.footer .widget-links ul li {
  display: block;
}

.footer .widget-links ul li a {
  color: #9b9b9b;
  font-size: 14px;
  font-weight: 400;
  line-height: 33px;
  text-transform: capitalize;
  font-family: 'Roboto', sans-serif;
}

.footer .widget-links ul li a:hover {
  color: #e11d07;
}

.footer .widget-contact .widget-content p {
  color: #ffffff;
  margin-bottom: 15px;
}

.footer .widget-contact .widget-content .phone {
  margin-bottom: 20px;
}

.footer .widget-contact .widget-content .phone a {
  color: #e11d07;
  font-family: 'Rubik', sans-serif;
  font-size: 22px;
}

.footer .widget-contact .widget-content .phone a i {
  display: inline-block;
  font-size: 18px;
  margin-right: 9px;
}

.footer .widget-contact .widget-content .address a {
  color: #9b9b9b;
  font-size: 14px;
}

.footer .widget-contact .widget-content .address a:hover {
  color: #e11d07;
}

.footer-copyright {
  padding: 33px 0 40px;
  font-size: 13px;
  font-family: 'Rubik', sans-serif;
  color: #9b9b9b;
}

.footer-copyright .copyright {
  margin-bottom: 15px;
}

.footer-copyright .module-social {
  height: auto;
  line-height: normal;
}

.footer-copyright .module-social a {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.footer-copyright-links {
  text-align: right;
}

.footer-copyright-links li {
  display: inline-block;
}

.footer-copyright-links li a {
  display: block;
  color: #9b9b9b;
  font-size: 13px;
  line-height: 1;
  display: block;
  padding-left: 16px;
  position: relative;
}

.footer-copyright-links li a:hover {
  color: #ffffff;
}

.footer-copyright-links li a:before {
  content: '';
  position: absolute;
  top: 0;
  left: 8px;
  height: 10px;
  width: 1px;
  background-color: #9b9b9b;
}

.footer-copyright-links li:first-child a :before {
  display: none;
}

.footer-widget-recent .entry {
  margin-bottom: 15px;
}

.footer-widget-recent .entry .entry-title a {
  color: #7f7f7f;
  font-size: 14px;
  line-height: 23px;
  display: block;
  margin-bottom: 5px;
}

.footer-widget-recent .entry .entry-title a:hover {
  color: #e11d07;
}

.footer-widget-recent .entry .entry-meta {
  color: #9b9b9b;
  font-size: 12px;
}

.footer-widget-recent .entry:last-of-type {
  margin-bottom: 0;
}

.widget-instagram a {
  display: inline-block;
  margin: 2px;
  position: relative;
  width: 59px;
  height: 59px;
}

.widget-instagram a img {
  max-width: 100%;
  height: auto;
}

.widget-instagram a:hover .insta-hover {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.widget-instagram .insta-hover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-transition: all 350ms;
  -o-transition: all 350ms;
  transition: all 350ms;
}

.widget-instagram .insta-hover i {
  line-height: 59px;
  display: block;
  background-color: #e11d07;
  color: #ffffff;
  font-size: 11px;
  text-align: center;
}

.footer-light {
  background-color: white;
}

.footer-light .footer-widget-title h5 {
  color: #1b1a1a;
}

.footer-light .widget-newsletter .form-control {
  border: 1px solid #eaeaea;
}

.footer-light .widget-newsletter button:hover {
  background-color: #1b1a1a;
  color: #ffffff;
}

.footer-light .footer-copyright {
  border-color: #eaeaea;
}

/* Custom, iPhone Retina */
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .footer .widget-newsletter form {
    max-width: 400px;
  }

  .footer .pull-right {
    float: none !important;
  }
}

@-webkit-keyframes alertfadein {
  0% {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  100% {
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes alertfadein {
  0% {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  100% {
    -webkit-transform: none;
    transform: none;
  }
}

.about .about-block .block-custom .detail {
  position: relative;
  padding-left: 20px;
  margin-bottom: 15px;
}

@media screen and (max-width: 991.98px) {
  .about .about-block .block-custom .detail {
    text-align: left;
  }
}

.about .about-block .block-custom .detail:last-child {
  margin-bottom: 0;
}

.about .about-block .block-custom .detail::before {
  content: '';
  position: absolute;
  top: 10px;
  left: 0;
  width: 8px;
  height: 8px;
  background-color: #fff;
  border-radius: 2px;
}

.about .about-block .block-custom .detail h6 {
  font-family: 'Rubik', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 29px;
  color: #fff;
  text-transform: capitalize;
  margin-bottom: 10px;
}

.about .about-block .block-custom .detail p {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 23px;
  color: #9b9b9b;
  margin-bottom: 0;
}

/* Footer Widget Başlık ve İçerik Stili */
.footer .footer-widget-title {
  display: block; /* İnline blok yerine blok olarak ayarla */
  margin-bottom: 10px; /* Bağlantılar arasında boşluk bırak */
}

.footer .footer-widget-title h5 {
  display: block; /* İnline blok yerine blok olarak ayarla */
  margin-bottom: 10px; /* Bağlantılar arasında boşluk bırak */
}

.footer .widget-content {
  display: block; /* İnline blok yerine blok olarak ayarla */
  color: #9b9b9b;
  font-size: 14px;
  font-weight: 400;
  line-height: 25px;
  margin-bottom: 25px;
}

.footer .module-social {
  display: block; /* İnline blok yerine blok olarak ayarla */
  height: auto;
  line-height: normal;
  margin-bottom: 25px; /* Bağlantılar arasında boşluk bırak */
}

.footer .module-social a {
  color: #9b9b9b;
  font-size: 20px;
  margin-right: 15px;
}

.footer .module-social a:hover {
  color: #e11d07;
}

/* Footer Bağlantılar Stili */
.footer .widget-links ul {
  display: inline-block;
  vertical-align: top;
  padding-left: 0;
  list-style: none;
  margin-bottom: 0;
  overflow: hidden;
}

.footer .widget-links ul li {
  display: inline-block;
  margin-right: 20px;
}

.footer .widget-links ul li a {
  color: #9b9b9b;
  font-size: 14px;
  font-weight: 400;
  text-decoration: none;
}

.footer .widget-links ul li a:hover {
  color: #e11d07;
}
.footer .footer-center {
  padding-top: 0;
  padding-bottom: 35px;
  display: flex; /* Bu satırı ekle */
  flex-wrap: wrap; /* Bu satırı ekle */
}

.footer .footer-widget {
  text-align: left;
  flex: 1; /* Bu satırı ekle */
  margin-right: 20px; /* Bu satırı ekle - widgetler arasında boşluk bırakmak için */
}

@media screen and (max-width: 580px) {
  .footer .footer-widget {
    text-align: center;
    margin-right: 0; /* Küçük ekranlarda boşluğu sıfırla */
    margin-bottom: 20px; /* Boşluk bırakmak için alt alta geçişte */
  }
}
@media screen and (min-width: 1000px) {
  .row {
    display: flex;
  }
}

@media screen and (max-width: 1000px) {
  .container {
    align-items: center;
    text-align: center;
  }
}
