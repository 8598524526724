.hakkimizda-page {
  padding-top: 150px; /* margin yerine padding kullanabilirsiniz */
}

.hakkimizda-content {
  display: flex;
}

.hakkimizda-section-left {
  width: 60%;
  padding: 20px;
  display: flex;
  flex-direction: column; /* İç içe geçmiş elemanları dikey hizala */
  align-items: center; /* Elemanları yatayda ortala */
  margin-top: 20px;
}
.hakkimizda-section-right {
  position: relative; /* Pozisyonu belirt */
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.hakkimizda-header-1 {
  padding: 20px;
  margin-bottom: 20px;
}
.hakkimizda-header-2 {
  padding: 20px;
  margin-bottom: 20px;
  margin-right: 0; /* Önceki değeri 100px'den 0'a düşürdük */
}

.hakkimizda-title-1 {
  margin-bottom: 10px;
  text-align: center;
  color: #c81a06;
}
.hakkimizda-title-2 {
  margin-bottom: 20px;
  color: #c81a06;
}
.hakkimizda-text {
  margin-bottom: 20px;
}

.hakkimizda-icons {
  position: absolute;
  left: -10px; /* İkonları sola kaydır */
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 30px;
  width: 60px;
}

.hakkimizda-icon {
  margin-bottom: 160px; /* Ikonlar arasına boşluk bırak */
}

.icon-1,
.icon-2,
.icon-3 {
  font-size: 3em;
}

/* Araya boşluk eklemek için yeni stil */
.hakkimizda-section-right > .hakkimizda-header + .hakkimizda-header {
  margin-top: 15px;
}
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.hakkimizda-section-right {
  animation: fadeIn 1s ease-out; /* Animasyon süresini ve geçiş efektini ayarlayabilirsiniz */
}
@media only screen and (max-width: 1557px) {
  .hakkimizda-icons {
    margin-top: 35px;
  }
  .icon-2 {
    margin-top: -15px;
  }
  .icon-3 {
    margin-top: 20px;
  }
}
@media only screen and (max-width: 1514px) {
  .hakkimizda-icons {
    margin-top: 45px;
  }

  .icon-3 {
    margin-top: 25px;
  }
}

@media only screen and (max-width: 1000px) {
  .hakkimizda-content {
    flex-direction: column;
    align-items: center;
  }
  .hakkimizda-page {
    flex-direction: column;
    align-items: center;
  }

  .hakkimizda-section-left,
  .hakkimizda-section-right {
    width: 100%;
  }

  .hakkimizda-icon {
    margin-top: 6px;
  }

  .hakkimizda-icons {
    left: 10%; /* İkons grubunu sola kaydırma */
    margin-bottom: 10px;
  }

  .hakkimizda-icons {
    margin-top: 45px;
  }
  .icon-2 {
    margin-top: 15px;
  }
  .icon-3 {
    margin-top: 10px;
  }

  .hakkimizda-header-2 {
    align-items: center;
    margin-left: auto; /* Sayfa başına hizalamak için */
    margin-right: auto; /* Sayfa sonuna hizalamak için */
    max-width: 600px; /* İstediğin genişliği ayarla */
  }
}

@media only screen and (max-width: 900px) {
  .hakkimizda-icon {
    margin-top: 6px;
  }

  .hakkimizda-icons {
    left: 8%; /* İkons grubunu sola kaydırma */
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 850px) {
  .hakkimizda-icon {
    margin-top: 6px;
  }

  .hakkimizda-icons {
    left: 6%; /* İkons grubunu sola kaydırma */
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 780px) {
  .hakkimizda-icons {
    left: 13%; /* İkons grubunu sola kaydırma */
    margin-top: 6px;
  }
  .hakkimizda-icons {
    margin-top: -5px;
  }
  .icon-2 {
    margin-top: 15px;
  }
  .icon-3 {
    margin-top: 10px;
  }
}

@media only screen and (max-width: 750px) {
  .hakkimizda-icons {
    left: 11%; /* İkons grubunu sola kaydırma */
  }
}

@media only screen and (max-width: 720px) {
  .hakkimizda-icons {
    left: 9%; /* İkons grubunu sola kaydırma */
  }
}

@media only screen and (max-width: 680px) {
  .hakkimizda-icons {
    left: 7%; /* İkons grubunu sola kaydırma */
  }
}

@media only screen and (max-width: 570px) {
  .hakkimizda-icons {
    left: 7%; /* İkons grubunu sola kaydırma */
  }
  .hakkimizda-text {
    font-size: 15px;
  }
}

@media only screen and (max-width: 480px) {
  .hakkimizda-text {
    font-size: 13px;
  }
  .hakkimizda-icons {
    left: 7%; /* İkons grubunu sola kaydırma */
  }
  .icon-2 {
    margin-top: -20px;
  }
}

@media only screen and (max-width: 423px) {
  .hakkimizda-icons {
    margin-top: -5px;
  }
  .icon-2 {
    margin-top: 10px;
  }
  .icon-3 {
    margin-top: 0px;
  }
}

@media only screen and (max-width: 368px) {
  .hakkimizda-icons {
    margin-top: -5px;
  }
  .icon-2 {
    margin-top: 10px;
  }
  .icon-3 {
    margin-top: 40px;
  }
}
@media only screen and (max-width: 340px) {
  .hakkimizda-text {
    font-size: 11px;
  }
  .hakkimizda-icons {
    margin-top: -5px;
  }
  .icon-2 {
    margin-top: -5px;
  }
  .icon-3 {
    margin-top: 0px;
  }
}
@media only screen and (max-width: 308px) {
  .hakkimizda-icons {
    margin-top: -5px;
  }
  .icon-2 {
    margin-top: 10px;
  }
  .icon-3 {
    margin-top: 20px;
  }
}
