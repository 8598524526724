@import url('https://fonts.googleapis.com/css2?family=Courier+Prime:ital,wght@0,400;0,700;1,400;1,700&family=El+Messiri:wght@400..700&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Oswald:wght@200..700&family=Saira+Extra+Condensed:wght@100;200;300;400;500;600;700;800;900&family=Teko:wght@300..700&display=swap');

:root {
  --primary-100: #e2e0ff;
  --primary-200: #c1beff;
  --primary-300: #a29dff;
  --primary-400: #837dff;
  --primary-500: #645cff;
  --primary-600: #504acc;
  --primary-700: #3c3799;
  --primary-800: #282566;
  --primary-900: #141233;

  --grey-50: #f8fafc;
  --grey-100: #f1f5f9;
  --grey-200: #e2e8f0;
  --grey-300: #cbd5e1;
  --grey-400: #94a3b8;
  --grey-500: #64748b;
  --grey-600: #475569;
  --grey-700: #334155;
  --grey-800: #1e293b;
  --grey-900: #0f172a;

  --black: #222;
  --white: #fff;
  --red-light: #f8d7da;
  --red-dark: #842029;
  --green-light: #d1e7dd;
  --green-dark: #0f5132;

  --small-text: 0.875rem;
  --extra-small-text: 0.7em;

  --backgroundColor: var(--white);
  --textColor: var(--grey-900);
  --borderRadius: 0.25rem;
  --letterSpacing: 1px;
  --transition: 0.3s ease-in-out all;
  --max-width: 1120px;
  --fixed-width: 600px;

  --shadow-1: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  --shadow-2: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  --shadow-3: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  --shadow-4: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
}

/* Form styles */
body {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.contacts {
  flex: 1;
}

.footer {
  margin-top: auto;
}

.contact-info {
  display: flex;
  flex-direction: column;
}

.contact-info > div {
  margin-bottom: 20px; /* İstenilen alt boşluk */
}

.contact-info p {
  margin: 5px 0;
}

.App {
  padding-top: 150px; /* İstenilen aşağı kaydırma miktarı */
  position: relative;
  z-index: 1;
}

.heading-map {
  font-family: 'Marhey', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 2rem;
  text-transform: uppercase;
  color: #c81a06;
  margin-left: 29%;
}

.heading-form {
  font-family: 'Marhey', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 2rem;
  text-transform: uppercase;
  color: #c81a06;
  margin-left: 20%;
}

.map-container,
.form-container {
  width: 50%;
  box-sizing: border-box;
  padding: 0 20px;
}

.map-caption {
  font-size: 1.2rem;
  color: #333;
}

.content-container {
  display: flex;
  justify-content: space-between;
  max-width: 1200px; /* İsteğe bağlı olarak maksimum genişlik ayarlayabilirsiniz */
  margin: 0 auto;
}

.contact-form {
  display: flex;
  flex-direction: column;
  max-width: 500px;
  width: 100%;
}

.contact-form label {
  font-size: 1.1rem;
  margin-bottom: 5px;
}

.contact-form input[type='text'],
.contact-form input[type='email'],
.contact-form textarea {
  padding: 15px; /* Padding değerini artırarak öğe içeriğini genişletebilirsiniz */
  border: 1px solid #ddd;
  margin-bottom: 15px;
  width: 100%;
  box-sizing: border-box;
  font-size: 1.1rem;
}

.contact-form textarea {
  height: 200px; /* Textarea yüksekliğini artırarak daha büyük bir alan sağlayabilirsiniz */
}

.contact-form button[type='submit'] {
  padding: 10px 20px;
  font-size: 1rem; /* Metin boyutunu ayarlayabilirsiniz */
  background-color: #c81a06;
  color: #000000;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.contact-form button[type='submit']:hover {
  background-color: #000000;
  color: rgb(255, 255, 255);
}
.contact-info {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.contact-info p {
  font-size: 18px;
  margin: 5px 0;
}
.map-wrapper {
  position: relative;
  width: 100%;
  height: 250px; /* Düzenlenen yükseklik */
  overflow: hidden;
}

.log-footer {
  margin-top: 200px;
  text-align: center;
  position: relative;
  bottom: 0;
  z-index: 1010;
}
@media (max-width: 1800px) {
  .heading-map {
    font-size: 2rem;
    text-transform: uppercase;

    margin-left: 27%;
  }
}
@media (max-width: 1700px) {
  .heading-map {
    font-size: 2rem;
    text-transform: uppercase;

    margin-left: 25%;
  }
}

@media (max-width: 1600px) {
  .heading-map {
    font-size: 2rem;
    text-transform: uppercase;

    margin-left: 24%;
  }
}

@media (max-width: 1500px) {
  .heading-map {
    font-size: 2rem;
    text-transform: uppercase;

    margin-left: 20%;
  }
}
@media (max-width: 1300px) {
  .heading-map {
    font-size: 2rem;
    text-transform: uppercase;

    margin-left: 17%;
  }
}

@media (max-width: 1200px) {
  .heading-map {
    font-size: 2rem;
    text-transform: uppercase;

    margin-left: 15%;
  }
}

@media (max-width: 1000px) {
  .heading-map {
    font-size: 2rem;
    text-transform: uppercase;

    margin-left: 40%;
  }
  .content-container {
    flex-direction: column;
    align-items: center;
  }

  .map-container,
  .form-container {
    width: 100%;
  }
  .map-wrapper {
    align-items: center;
    text-align: center;
  }
  .contact-info,
  .contact-form {
    margin-right: 0;
  }

  .contact-info {
    order: 2;
  }

  .heading-form {
    font-size: 2rem;
    text-transform: uppercase;

    margin-left: auto; /* Sayfa başına hizalamak için */
    margin-right: auto; /* Sayfa sonuna hizalamak için */
    max-width: 300px; /* İstediğin genişliği ayarla */
  }

  .inputs {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .contact-form {
    max-width: 600px;
    width: 100%;
    margin: 0 auto; /* Sayfa ortasına hizalamak için */
  }

  .contact-form label,
  .contact-form input,
  .contact-form textarea,
  .contact-form button {
    width: 100%;
    box-sizing: border-box;
  }
  .contact-info {
    display: flex;
    justify-content: center;
    transition: margin 0.3s ease; /* Transition ekleniyor */
  }

  .contact-info > div {
    margin: 0 20%; /* Sağ ve sol boşluk ayarı */
    max-width: 400px; /* Opsiyonel olarak maksimum genişlik ayarı */
    transition: margin 0.3s ease; /* Transition ekleniyor */
  }
}

@media (max-width: 850px) {
  .contact-info {
    display: flex;
    justify-content: center;
    transition: margin 0.3s ease; /* Transition ekleniyor */
  }
  .contact-info > div {
    margin: 0 12%; /* Sağ ve sol boşluk ayarı */
    max-width: 400px; /* Opsiyonel olarak maksimum genişlik ayarı */
    transition: margin 0.3s ease; /* Transition ekleniyor */
  }
}
@media (max-width: 750px) {
  .heading-map {
    font-size: 2rem;
    text-transform: uppercase;

    margin-left: 35%;
  }
  .contact-info {
    display: flex;
    justify-content: center;
    transition: margin 0.3s ease; /* Transition ekleniyor */
  }

  .contact-info > div {
    margin: 0 7%; /* Sağ ve sol boşluk ayarı */
    max-width: 400px; /* Opsiyonel olarak maksimum genişlik ayarı */
    transition: margin 0.3s ease; /* Transition ekleniyor */
  }
}
@media (max-width: 650px) {
  .contact-info {
    display: flex;
    justify-content: center;
    transition: margin 0.3s ease; /* Transition ekleniyor */
  }

  .contact-info > div {
    margin: 0 1%; /* Sağ ve sol boşluk ayarı */
    max-width: 400px; /* Opsiyonel olarak maksimum genişlik ayarı */
    transition: margin 0.3s ease; /* Transition ekleniyor */
  }
}

@media (max-width: 500px) {
  .map-container {
    overflow-x: auto; /* Yatay kaydırma çubuğu görünmesini sağlar */
  }

  .map-wrapper iframe {
    right: 70px; /* İstenilen kayma miktarı */
  }
}

@media (max-width: 490px) {
  .heading-map {
    text-transform: uppercase;

    text-align: center;
    margin-left: 1%;
  }

  .info {
    font-size: 13px;
  }
  .heading-map {
    font-size: 25px;
  }
  .heading-form {
    text-align: center;
    font-size: 25px;
  }
}
