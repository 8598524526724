.products-page-header {
  text-align: center;
  margin-top: 100px; /* İstediğiniz boşluğu ayarlayabilirsiniz */
}

.products-page-header h2 {
  margin: 0;
}
.container-header {
  color: #c81a06;
  align-items: center;
  text-align: center;
  margin-top: 100px;
  font-size: 50px;
}

@media (max-width: 650px) {
  .container-header {
    font-size: 40px;
  }
}
@media (max-width: 500px) {
  .container-header {
    font-size: 30px;
  }
}
@media (max-width: 400px) {
  .container-header {
    font-size: 27px;
  }
}
