@import url('https://fonts.googleapis.com/css2?family=Black+Ops+One&family=Monoton&family=Oswald:wght@200..700&family=Teko:wght@300..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Marhey:wght@300..700&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Oswald:wght@200..700&family=Saira+Extra+Condensed:wght@100;200;300;400;500;600;700;800;900&family=Teko:wght@300..700&display=swap');
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #ffffff;
  padding: 1rem;
  display: flex;
  align-items: center;
  z-index: 1000;
  height: 100px;
}

.navbar a {
  color: var(--white);
  margin-left: 1rem;
  text-decoration: none;
  font-weight: 400;
  transition: color 0.3s ease-in-out;
}

.navbar a:hover {
  color: black;
}

.logo {
  display: flex;
  align-items: center;
  margin-bottom: 18px;
}

.logo img {
  width: 450px;
  height: 75px;
}
@media (max-width: 1000px) {
  .logo {
    display: flex;
    align-items: center;
    margin-bottom: 18px;
  }

  .logo img {
    width: 400px;
    height: 75px;
  }
}
@media (max-width: 800px) {
  .logo {
    display: flex;
    align-items: center;
    margin-bottom: 18px;
  }

  .logo img {
    width: 300px;
    height: 65px;
  }
}

@media (max-width: 500px) {
  .logo {
    display: flex;
    align-items: center;
    margin-bottom: 18px;
  }

  .logo img {
    width: 250px;
    height: 55px;
  }
}
@media (max-width: 400px) {
  .logo {
    display: flex;
    align-items: center;
    margin-bottom: 18px;
  }

  .logo img {
    width: 200px;
    height: 50px;
  }
}

.logo-header {
  font-family: 'Black Ops One', system-ui;
  font-weight: 400;
  font-style: normal;
  color: var(--white);
  font-weight: 100;
  font-size: 25px;
  margin-left: 10px;
  margin-top: 20px;
}

.links {
  font-family: 'Marhey', sans-serif;
  font-weight: 400;
  font-style: normal;
  margin-left: auto;
  display: flex;
  align-items: center;
}

.links a {
  color: black;
  margin-left: 15px;
  text-decoration: none;
  font-weight: 400;
  transition: color 0.3s ease-in-out;
}

.links a:hover {
  color: #c81a06;
}

@media (min-width: 1001px) {
  .menu-icon {
    display: none;
  }
}
@media (max-width: 1000px) {
  .desktop-links {
    display: none;
  }
  .menu-icon {
    cursor: pointer;
    color: #c81a06;
    font-size: 20px;
    margin-left: auto;
    display: block;
  }

  .mobile-links {
    display: none;
    position: absolute;
    top: 100px;
    right: 0;
    background-color: #161616;
    flex-direction: column;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    z-index: 2020;
    width: 100%;
    height: 200px;
  }

  .mobile-links a {
    color: rgb(255, 255, 255);
    padding: 10px;
    text-decoration: none;
    transition: color 0.3s ease-in-out;
  }

  .mobile-links a:hover {
    color: #c81a06;
  }

  .mobile-links.active {
    display: flex;
  }
  .hidden {
    display: none;
  }
}

@media (max-width: 500px) {
  .mobile-links a {
    color: var(--white);
    padding: 10px;
    text-decoration: none;
    transition: color 0.3s ease-in-out;
  }

  .mobile-links a:hover {
    color: black;
  }

  .mobile-links.active {
    display: flex;
  }
  .hidden {
    display: none;
  }
}

@media (max-width: 400px) {
  .logo-header {
    font-size: 18px;
  }
  .mobile-links a {
    color: var(--white);
    padding: 10px;
    text-decoration: none;
    transition: color 0.3s ease-in-out;
  }

  .mobile-links a:hover {
    color: black;
  }

  .mobile-links.active {
    display: flex;
  }
  .hidden {
    display: none;
  }
}
