@import url('https://fonts.googleapis.com/css2?family=Courier+Prime:ital,wght@0,400;0,700;1,400;1,700&family=El+Messiri:wght@400..700&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Oswald:wght@200..700&family=Saira+Extra+Condensed:wght@100;200;300;400;500;600;700;800;900&family=Teko:wght@300..700&display=swap');

.container {
  position: relative;
  width: 100%;
  max-width: 1900px;
  margin: 0 auto;
  overflow: hidden;
}
.header-image {
  margin-top: 100px;
  width: 100vw;
  height: 750px;
  display: block;
  object-fit: cover;
}
.header-image::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3); /* Siyah renk ve %30 opaklık */
  z-index: 1;
}

.button-container {
  position: absolute;
  top: 50%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  transform: translateY(-50%);
  z-index: 2;
}

.buton {
  background-color: transparent;
  border: none;
  color: #adadad;
  padding: 1px 1px;
  text-align: center;
  text-decoration: none;
  transition: color 0.3s ease;
  cursor: pointer;
}

.buton:hover {
  color: #ffffff;
}
.overlay-text {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-style: normal;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #ffffff;
  font-size: 20px;
  text-align: center;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.6); /* Siyah arka plan ve %50 opaklık */
  padding: 20px; /* İstediğiniz kadar iç boşluk ekleyebilirsiniz */
  width: 60%;
  margin-top: 60px;
}

@media (max-width: 1400px) {
  .header-image {
    margin-top: 100px;
  }
}

@media (max-width: 1000px) {
  .overlay-text {
    font-size: 18px;
    padding: 20px; /* İstediğiniz kadar iç boşluk ekleyebilirsiniz */
    width: 80%; /* Örnek olarak genişliği %70 olarak ayarladık, istediğiniz değeri kullanabilirsiniz */
    box-sizing: border-box;
  }
  .header-image {
    width: 100vw;
    height: 500px;
    display: block;
    object-fit: cover;
    margin-top: 85px;
  }
}
@media (max-width: 800px) {
  .overlay-text {
    font-size: 15px;
    margin-top: 20px;
  }
}
@media (max-width: 700px) {
  .overlay-text {
    font-size: 15px;
    margin-top: 40px;
  }
}
@media (max-width: 650px) {
  .overlay-text {
    font-size: 12px;
    margin-top: 40px;
    box-sizing: border-box;
  }
  .header-image {
    height: auto; /* Otomatik yükseklik ayarı ile aşağıdan yukarı doğru uzar */
    display: block;
    object-fit: cover;
    margin-top: 70px;
  }
}
@media (max-width: 500px) {
  .overlay-text {
    font-size: 11px;
    margin-top: 40px;
    padding: 10px; /* İstediğiniz kadar iç boşluk ekleyebilirsiniz */
    width: 80%; /* Örnek olarak genişliği %70 olarak ayarladık, istediğiniz değeri kullanabilirsiniz */
    box-sizing: border-box;
  }
  .header-image {
    width: 100%;
    display: block;
    object-fit: cover;
    margin-top: 70px;
  }
}

@media (max-width: 400px) {
  .overlay-text {
    font-size: 9px;
    margin-top: 40px;
    padding: 10px; /* İstediğiniz kadar iç boşluk ekleyebilirsiniz */
    width: 70%; /* Örnek olarak genişliği %70 olarak ayarladık, istediğiniz değeri kullanabilirsiniz */
    box-sizing: border-box;
  }
  .header-image {
    margin-top: 70px;
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
  }
}
/* ABOUT SECTION CSS */

.home-section-1,
.home-section-2 {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 100px;
}

.home-text-1,
.home-text-2 {
  font-family: 'El Messiri', sans-serif;
  font-weight: 400;
  font-style: normal;
  max-width: 600px;
  width: 100%;
  margin: 20px;
  padding: 20px;
  text-align: left;
  margin-left: 5%;
  font-size: 15px;
}

.home-image-1,
.home-image-2 {
  width: 100%;
  max-width: 700px;
  max-height: 650px;
  margin: 10px;
}

@media (max-width: 1100px) {
  .home-image-1,
  .home-image-2 {
    width: 100%;
    max-width: 750px;
    margin: 1px;
  }
}

@media (min-width: 1000px) {
  .container {
    flex-direction: row;
  }

  .image-container,
  .home-text-1,
  .home-text-2 {
    width: 50%;
  }

  .home-image-1,
  .home-image-2 {
    width: 50%;
  }
}

@media (max-width: 1000px) {
  .home-section-1,
  .home-section-2 {
    flex-direction: column;
  }

  .home-text-1,
  .home-text-2,
  .home-image-1,
  .home-image-2 {
    width: 100%;
  }
  .home-header-1,
  .home-header-2 {
    margin-bottom: 50px;
  }
}
@media (max-width: 500px) {
  .home-text-1,
  .home-text-2 {
    font-size: 14px;
  }
}
@media (max-width: 400px) {
  .home-text-1,
  .home-text-2 {
    font-size: 11px;
  }
}
.home-header-1 {
  position: absolute;
  right: 15%;
  transform: translateY(-50%);
  width: 30%;
  max-width: 450px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #c81a06;
  margin-top: 60px;
}
@media (max-width: 2900px) {
  .home-header-1 {
    right: 7%;
    width: 40%;
    max-width: 1350px;
  }
}

@media (max-width: 1700px) {
  .home-header-1 {
    right: 3%;
    width: 40%;
    max-width: 1350px;
  }
}

@media (max-width: 1300px) {
  .home-header-1 {
    right: 3%;
    width: 40%;
    max-width: 700px;
    font-size: 1.5rem;
  }
  h4 {
    font-size: 22px;
  }
}

@media (max-width: 1250px) {
  .home-header-1 {
    right: 3%;
    width: 40%;
    max-width: 700px;
  }
}
@media (max-width: 1160px) {
  .home-header-1 {
    right: 2%;
    width: 40%;
    max-width: 650px;
  }
}

@media (max-width: 1100px) {
  .home-header-1 {
    right: 1%;
    width: 50%;
    max-width: 450px;
  }
}

@media (max-width: 1000px) {
  .home-header-1 {
    right: 0;
    width: 100%;
    max-width: none;
    margin-top: 50px;
  }
  .home-image-1 {
    margin-top: 60px;
  }
}

@media (max-width: 400px) {
  .home-header-1 {
    right: 0;
    width: 100%;
    max-width: none;
    margin-top: 50px;
  }
}

.home-header-2 {
  position: absolute;
  right: 50%;
  transform: translateY(-50%);
  width: 50%;
  max-width: 1350px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  color: #c81a06;
  margin-top: 50px;
}

@media (max-width: 2600px) {
  .home-header-2 {
    right: 45%;
    width: 50%;
    max-width: 1350px;
  }
}

@media (max-width: 1850px) {
  .home-header-2 {
    right: 47%;
    width: 50%;
    max-width: 1350px;
  }
}

@media (max-width: 1750px) {
  .home-header-2 {
    right: 50%;
    width: 50%;
    max-width: 1150px;
  }
  h4 {
    font-size: 21px;
  }
}

@media (max-width: 1300px) {
  .home-header-2 {
    right: 45%;
    width: 58%;
    max-width: 900px;
    margin-bottom: 60px;
  }
  h4 {
    font-size: 20px;
  }
}

@media (max-width: 1200px) {
  .home-header-2 {
    margin-top: 35px;
  }
  h4 {
    font-size: 17px;
  }
}
@media (max-width: 1100px) {
  h4 {
    font-size: 16px;
  }
}

@media (max-width: 1000px) {
  .home-header-2 {
    right: 0;
    width: 100%;
    max-width: none;
  }
  h4 {
    font-size: 22px;
  }
}

@media (max-width: 650px) {
  h4 {
    font-size: 19px;
  }
}
@media (max-width: 500px) {
  h4 {
    font-size: 15px;
  }
}
@media (max-width: 400px) {
  h4 {
    font-size: 12px;
  }
}
span {
  color: black;
  font-weight: bold;
}

.home-header-1,
.home-header-2 {
  font-family: 'Marhey', sans-serif;
  font-weight: 400;
  font-style: normal;
}

/* HİZMET EDİLEN FİRMA CSS**/

.home-section-5 {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 100px;
}

.home-image-5 {
  width: 100%;
  max-width: 800px;
  margin: 20px;
}

.home-text-5 {
  font-family: 'Cabin Condensed', sans-serif;
  font-weight: 400;
  font-style: normal;
  max-width: 600px;
  margin: 20px;
  padding: 20px;
  text-align: left;
  margin-left: 80px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  margin-top: 100px;
}

.post {
  text-align: center;
  margin-bottom: 20px;
  position: relative;
  height: 150px;
  width: 100%;
  cursor: pointer;
}

.post-t {
  height: 120px; /*KÜÇÜLT*/
  width: 0px;
  background-color: #c81a06;
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  transition: 0.7s ease;
}

.post-t h4 {
  color: #ffffff;
  font-size: 17px;
  padding: 10px 30px;
}

.post:hover .post-t {
  width: 100%;
}

.post img {
  display: block;
  outline: solid 5px #c81a06;
  height: 120px; /*KÜÇÜLT*/
  width: 100%;
}

@media (min-width: 768px) {
  .home-text-5 {
    width: 50%;
  }
  .home-image-5 {
    width: 50%;
  }
}

@media (max-width: 1500px) {
  .home-text-5 {
    grid-template-columns: repeat(3, 1fr);
  }

  .post {
    width: calc(100.33% - 20px);
    min-width: calc(33.33% - 20px);
  }
  .post-t h4 {
    color: #ffffff;
    font-size: 20px;
    padding: 10px 30px;
  }
}

@media (max-width: 1300px) {
  .home-text-5 {
    grid-template-columns: repeat(2, 1fr);
  }

  .post {
    width: calc(100% - 20px);
    min-width: calc(50% - 20px);
  }
  .post img {
    height: 120px; /*KÜÇÜLT*/
    width: 80%;
  }
  .post-t {
    height: 120px; /*KÜÇÜLT*/
  }
  .post:hover .post-t {
    width: 80%;
  }
  .post-t h4 {
    color: #ffffff;
    font-size: 20px;
    padding: 10px 30px;
  }
}
@media (max-width: 1100px) {
  .home-text-5 {
    grid-template-columns: repeat(2, 1fr);
  }

  .post {
    width: calc(100% - 20px);
    min-width: calc(50% - 20px);
  }
  .post img {
    height: 130px; /*KÜÇÜLT*/
    width: 90%;
  }
  .post-t {
    height: 130px; /*KÜÇÜLT*/
  }
  .post:hover .post-t {
    width: 90%;
  }
  .post-t h4 {
    color: #ffffff;
    font-size: 20px;
    padding: 10px 30px;
  }
}
@media (max-width: 1000px) {
  .home-section-5 {
    flex-direction: column;
  }
  .home-text-5 {
    grid-template-columns: repeat(3, 2fr);
    width: 600px;
  }

  .home-text-5 > div {
    width: 70%;
  }
  .home-image-5 {
    width: 90%;
    margin-top: 100px;
  }
  .post img {
    height: 120px; /*KÜÇÜLT*/
    width: 100%;
  }
  .post-t {
    height: 120px; /*KÜÇÜLT*/
  }
  .post:hover .post-t {
    width: 100%;
  }
  .post-t h4 {
    color: #ffffff;
    font-size: 17px;
    padding: 10px 30px;
  }
}

@media (max-width: 900px) {
  .home-text-5 {
    grid-template-columns: repeat(3, 2fr);
    margin-left: 15px;
  }

  .post {
    width: 90%;
    min-width: 60%;
  }
  .post img {
    height: 120px; /*KÜÇÜLT*/
    width: 100%;
  }
  .post-t {
    height: 120px; /*KÜÇÜLT*/
  }
  .post:hover .post-t {
    width: 100%;
  }
  .post-t h4 {
    color: #ffffff;
    font-size: 17px;
    padding: 10px 30px;
  }
}

@media (max-width: 799px) {
  .home-text-5 {
    grid-template-columns: repeat(2, 2fr);
    margin-left: auto;
  }

  .post {
    width: calc(100% - 20px);
    min-width: calc(30% - 20px);
    height: 100px;
  }
  .post img {
    height: 100px; /*KÜÇÜLT*/
    width: 60%;
  }
  .post-t {
    height: 100px; /*KÜÇÜLT*/
  }
  .post:hover .post-t {
    width: 60%;
  }
  .post-t h4 {
    color: #ffffff;
    font-size: 17px;
    padding: 10px 30px;
  }
}

@media (max-width: 757px) {
  .home-text-5 {
    grid-template-columns: repeat(2, 2fr);
  }
  .post {
    left: 30px;
    height: 100px;
  }
}

@media (max-width: 768px) {
  .home-text-5 {
    grid-template-columns: repeat(2, 2fr);
    width: 600px;
    min-width: calc(20% - 20px);
    margin-left: auto;
  }

  .post {
    width: calc(100% - 20px);
    min-width: calc(70% - 20px);
    left: 25px;
    height: 100px;
  }
  .post-t h4 {
    color: #ffffff;
    font-size: 17px;
    padding: 10px 30px;
  }
  .post img {
    height: 100px; /*KÜÇÜLT*/
    width: 70%;
  }
  .post-t {
    height: 100px; /*KÜÇÜLT*/
  }
  .post:hover .post-t {
    width: 70%;
  }
  .post-t h4 {
    color: #ffffff;
    font-size: 17px;
    padding: 10px 30px;
  }
}

@media (max-width: 710px) {
  .home-text-5 {
    grid-template-columns: repeat(2, 2fr);
    width: 500px;
  }

  .post {
    width: calc(100% - 20px);
    min-width: calc(70% - 20px);
    left: -20px;
    height: 100px;
  }
}

@media (max-width: 660px) {
  .home-text-5 {
    grid-template-columns: repeat(2, 2fr);
    width: 400px;
  }

  .post {
    width: calc(100% - 20px);
    min-width: calc(100% - 20px);
    left: -70px;
    height: 100px;
  }
}

@media (max-width: 620px) {
  .home-text-5 {
    grid-template-columns: repeat(2, 2fr);
    width: 400px;
  }

  .post {
    width: calc(100% - 20px);
    min-width: calc(100% - 20px);
    left: -40px;
    height: 100px;
  }
}

@media (max-width: 560px) {
  .home-text-5 {
    grid-template-columns: repeat(2, 2fr);
    width: 400px;
  }

  .post {
    width: calc(100% - 20px);
    min-width: calc(100% - 20px);
    left: -30px;
  }
}

@media (max-width: 535px) {
  .home-text-5 {
    grid-template-columns: repeat(3, 2fr);
    width: 400px;
  }

  .post {
    min-width: calc(100% - 80px);
    left: -10px;
    height: 80px;
  }

  .post img {
    height: 80px; /*KÜÇÜLT*/
    width: 100%;
  }
  .post-t {
    height: 80px; /*KÜÇÜLT*/
  }
  .post:hover .post-t {
    width: 100%;
  }
  .post-t h4 {
    color: #ffffff;
    font-size: 12px;
  }
}

@media (max-width: 495px) {
  .home-text-5 {
    grid-template-columns: repeat(3, 2fr);
    width: 400px;
  }

  .post {
    min-width: calc(100% - 80px);
    left: 10px;
    height: 80px;
  }

  .post img {
    height: 80px; /*KÜÇÜLT*/
    width: 100%;
  }
  .post-t {
    height: 80px; /*KÜÇÜLT*/
  }
  .post:hover .post-t {
    width: 100%;
  }
  .post-t h4 {
    color: #ffffff;
    font-size: 12px;
  }
}

@media (max-width: 450px) {
  .home-text-5 {
    grid-template-columns: repeat(3, 2fr);
    width: 400px;
  }

  .post {
    min-width: calc(100% - 80px);
    left: 15px;
    height: 90px;
  }
}

@media (max-width: 435px) {
  .home-text-5 {
    grid-template-columns: repeat(2, 2fr);
    width: 400px;
  }

  .post {
    min-width: calc(100% - 80px);
    left: 50px;
    height: 90px;
  }

  .post img {
    height: 80px; /*KÜÇÜLT*/
    width: 80%;
  }
  .post-t {
    height: 80px; /*KÜÇÜLT*/
  }
  .post:hover .post-t {
    width: 80%;
  }
  .post-t h4 {
    color: #ffffff;
    font-size: 12px;
  }
}

@media (max-width: 420px) {
  .home-text-5 {
    grid-template-columns: repeat(2, 2fr);
    width: 400px;
  }

  .post {
    min-width: calc(100% - 80px);
    left: 50px;
    height: 80px;
  }

  .post img {
    height: 80px; /*KÜÇÜLT*/
    width: 80%;
  }
  .post-t {
    height: 80px; /*KÜÇÜLT*/
  }
  .post:hover .post-t {
    width: 80%;
  }
  .post-t h4 {
    color: #ffffff;
    font-size: 14px;
  }
}

@media (max-width: 410px) {
  .home-text-5 {
    grid-template-columns: repeat(2, 2fr);
    width: 400px;
  }

  .post {
    left: 35px;
    height: 80px;
  }

  .post img {
    height: 80px; /*KÜÇÜLT*/
    width: 80%;
  }
  .post-t {
    height: 80px; /*KÜÇÜLT*/
  }
  .post:hover .post-t {
    width: 80%;
  }
  .post-t h4 {
    color: #ffffff;
    font-size: 14px;
  }
}

@media (max-width: 400px) {
  .home-text-5 {
    grid-template-columns: repeat(2, 2fr);
    width: 200px;
    grid-gap: 50px;
  }

  .post {
    left: -80px;
    height: 80px;
  }

  .post img {
    height: 80px; /*KÜÇÜLT*/
    width: 200%;
  }
  .post-t {
    height: 80px; /*KÜÇÜLT*/
  }
  .post:hover .post-t {
    width: 200%;
  }
  .post-t h4 {
    color: #ffffff;
    font-size: 12px;
  }
}

@media (max-width: 370px) {
  .home-text-5 {
    grid-template-columns: repeat(2, 2fr);
    width: 200px;
    grid-gap: 50px;
  }

  .post {
    left: -60px;
    height: 80px;
  }

  .post img {
    height: 80px; /*KÜÇÜLT*/
    width: 200%;
  }
  .post-t {
    height: 80px; /*KÜÇÜLT*/
  }
  .post:hover .post-t {
    width: 200%;
  }
  .post-t h4 {
    color: #ffffff;
    font-size: 12px;
  }
}

@media (max-width: 340px) {
  .home-text-5 {
    grid-template-columns: repeat(2, 2fr);
    width: 200px;
    grid-gap: 50px;
  }

  .post {
    left: -50px;
    height: 80px;
  }

  .post img {
    height: 80px; /*KÜÇÜLT*/
    width: 200%;
  }
  .post-t {
    height: 80px; /*KÜÇÜLT*/
  }
  .post:hover .post-t {
    width: 200%;
  }
  .post-t h4 {
    color: #ffffff;
    font-size: 12px;
  }
}

@media (max-width: 320px) {
  .home-text-5 {
    grid-template-columns: repeat(2, 2fr);
    width: 200px;
    grid-gap: 50px;
  }

  .post {
    left: -40px;
    height: 80px;
  }

  .post img {
    height: 80px; /*KÜÇÜLT*/
    width: 200%;
  }
  .post-t {
    height: 80px; /*KÜÇÜLT*/
  }
  .post:hover .post-t {
    width: 200%;
  }
  .post-t h4 {
    color: #ffffff;
    font-size: 12px;
  }
}
